import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index'),
    meta: {
      title: '必要EMA-报告查询-陶瓷报告-综合汇总报告',
      type: 'login',
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
