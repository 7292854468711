import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'
import * as ElIcon from '@element-plus/icons'

const app = createApp(App)

let baseUrl = "https://biyaochatapi.azurewebsites.net"
// let baseUrl = "http://chat.biyaoai.com/"
let cookieVal = "biyaoaiVal"
let cookieUserName = "username"
app.config.globalProperties.cookieVal = cookieVal;
app.config.globalProperties.cookieUserName = cookieUserName;
app.config.globalProperties.baseUrl = baseUrl;

let axiosAI = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': "",
    }
});
//拦截判断当前页面是否需要登陆，登陆后才可查看
router.beforeEach((to, from, next) => {
    if (to.meta.type === 'login') {
        if (document.cookie.indexOf(cookieVal) > -1 && getCookie(cookieVal)) {
            axiosAI.defaults.headers.Authorization = getCookie(cookieVal)
            next()
        } else {
            next("/")
        }
    } else{
        if (document.cookie.indexOf(cookieVal) > -1 && getCookie(cookieVal)) {
            next("/index")
        } else {
            next()
        }
    }
    // next()
})

app.config.globalProperties.axios = axiosAI;

Object.keys(ElIcon).forEach((key) => {
    app.component(key, ElIcon[key])
})
app.use(router,axios).use(ElementPlus).mount('#app')

// 根据key获取cookie value
function getCookie(name){
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for ( var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=");
        if (arr[0] == name){
            return arr[1];
        }
    }
    return "";
}
