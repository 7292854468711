<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Microsoft Yahei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body{
  padding: 0;
  margin: 0;
}
.el-button {
  height: 40px !important;
}
.el-button .el-loading-spinner {
  height: 40px !important;
  line-height: 40px;
}
.el-button .el-loading-spinner .circular {
  /*vertical-align: middle;*/
  /*width: 35px;*/
  display: none;
}
.el-button .el-loading-spinner p {
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
}
/*.el-loading-spinner .path {*/
/*  stroke: #2f4056 !important;*/
/*}*/
.el-message-box__title {
  white-space: pre-line;
}
.cursorPointer {
  cursor: pointer;
}
.el-message-box__title{
  display: inline-block;
  line-height: 1.5 !important;
}
</style>
